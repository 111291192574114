import moment from 'moment';
import {keyToFilterField} from '../../../forecast-app/shared/components/filters/filter_util';
import {GLOBAL_FILTER_CONTEXT, GLOBAL_FILTER_FIELD} from '../../../constants';
import {
	getFilterOperator,
	getUnifiedFilterOperator,
	isOperator,
} from '../../../forecast-app/shared/components/filters/filter_logic';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';

export const filtersToSearchQuery = (filters, addedFilters) => {
	const searchQuery = {
		filters: [],
	};
	addedFilters?.forEach(filter => {
		searchQuery.filters.push({
			field: keyToFilterField(filter.key),
			value: filter.value,
		});
	});

	if (filters?.person) {
		const context = GLOBAL_FILTER_CONTEXT.PEOPLE;
		for (const [key, value] of Object.entries(filters.person)) {
			if (isOperator(key)) {
				continue;
			}
			let field = keyToFilterField(key);
			const unifiedFilterOperator = getUnifiedFilterOperator(key, filters.person);
			if (field === GLOBAL_FILTER_FIELD.SKILL || field === GLOBAL_FILTER_FIELD.SKILL_AND) {
				if (hasFeatureFlag('unified_filters_reports')) {
					searchQuery.filters.push({
						field: GLOBAL_FILTER_FIELD.SKILL,
						value: value?.map(value =>
							value.skillId && value.skillLevelId
								? value.skillId + ':' + value.skillLevelId
								: value.skillId || value
						),
						context,
						operator: unifiedFilterOperator,
					});
				} else {
					// Backwards compatibility to old require-all logic
					const requireAll =
						field === GLOBAL_FILTER_FIELD.SKILL_AND || !!getFilterOperator(filters.person, key)?.requireAll;
					searchQuery.filters.push({
						field: requireAll ? GLOBAL_FILTER_FIELD.SKILL_AND : GLOBAL_FILTER_FIELD.SKILL,
						value: value?.map(value =>
							value.skillId && value.skillLevelId
								? value.skillId + ':' + value.skillLevelId
								: value.skillId || value
						),
						context,
					});
				}
			} else if (field === GLOBAL_FILTER_FIELD.PROJECT) {
				searchQuery.filters.push({
					field: GLOBAL_FILTER_FIELD.PROJECT_PERSON,
					value,
					context,
					operator: unifiedFilterOperator,
				});
				if (!hasFeatureFlag('ut_report_align_filters')) {
					searchQuery.filters.push({
						field: GLOBAL_FILTER_FIELD.PROJECT,
						value,
						context,
						operator: unifiedFilterOperator,
					});
				}
			} else {
				searchQuery.filters.push({
					field,
					value,
					context,
					operator: unifiedFilterOperator,
				});
			}
		}
	}
	if (filters?.project) {
		const context = GLOBAL_FILTER_CONTEXT.PROJECTS;
		for (const [key, value] of Object.entries(filters.project)) {
			if (isOperator(key)) {
				continue;
			}
			const unifiedFilterOperator = getUnifiedFilterOperator(key, filters.project);
			let field = keyToFilterField(key);
			searchQuery.filters.push({
				field,
				value,
				context,
				operator: unifiedFilterOperator,
			});
		}
	}
	return searchQuery;
};

export const convertProjectAndPersonSearchFilters = filters => {
	const filterSearchQuery = filtersToSearchQuery(filters, []);
	return filterSearchQuery.filters;
};

export const mapCompressedAggregatedResourceNumbers = (compressedAggregatedResourceNumbersArray, aggregates) => {
	return compressedAggregatedResourceNumbersArray.map(compressedAggregatedResourceNumbers => {
		const values = compressedAggregatedResourceNumbers.split(',');

		if (values.length - 2 !== aggregates.length) {
			throw new Error(
				`Mismatch between asked-for aggregated values and the values in the response. Aggregates asked for: ${aggregates.join(
					','
				)}. Values in response: ${values.slice(2).join(',')}`
			);
		}

		const aggregatedResourceNumbers = {
			startDate: moment(values[0], 'YYYY-MM-DD'),
			endDate: moment(values[1], 'YYYY-MM-DD'),
		};
		values.slice(2).forEach((value, index) => {
			const aggregate = aggregates[index];
			aggregatedResourceNumbers[aggregate] = +value;
		});

		return aggregatedResourceNumbers;
	});
};

const TASK_FALLBACK_FIELD_MAPPING = {
	// Only to be used in a transition phase for T&P.
	// If the FF is enabled for just some users in a company, and the combined company setting is enabled,
	// users without the FF still needs to see the old UT numbers.
	// When FF (tp_heatmap_spike) is rolled out, this can be deleted.
	plannedBillableProjectMinutes: 'tasksEstimateBillableMinutes',
	plannedNonBillableProjectMinutes: 'tasksEstimateNonBillableMinutes',
	plannedTotalMinutes: 'tasksEstimateTotalMinutes',
	plannedOverMinutes: 'tasksOverMinutes',
	plannedBillableUtilization: 'tasksBillableUtilization',
	plannedNonBillableUtilization: 'tasksNonBillableUtilization',
	plannedResourceUtilization: 'tasksResourceUtilization',

	remainingBillableProjectMinutes: 'tasksRemainingBillableMinutes',
	remainingNonBillableProjectMinutes: 'tasksRemainingNonBillableMinutes',
	remainingTotalMinutes: 'tasksRemainingTotalMinutes',
	remainingOverMinutes: 'tasksRemainingOverMinutes',
	remainingBillableUtilization: 'tasksRemainingBillableUtilization',
	remainingNonBillableUtilization: 'tasksRemainingNonBillableUtilization',
	remainingResourceUtilization: 'tasksRemainingResourceUtilization',

	forecastBillableProjectMinutes: 'tasksForecastBillableMinutes',
	forecastNonBillableProjectMinutes: 'tasksForecastNonBillableMinutes',
	forecastTotalMinutes: 'tasksForecastTotalMinutes',
	forecastOverMinutes: 'tasksForecastOverMinutes',
	forecastBillableUtilization: 'tasksForecastBillableUtilization',
	forecastNonBillableUtilization: 'tasksForecastNonBillableUtilization',
	forecastResourceUtilization: 'tasksForecastResourceUtilization',

	totalActualVsPlan: 'tasksTotalActualVsPlan',
	billableActualVsPlan: 'tasksBillableActualVsPlan',
	nonBillableActualVsPlan: 'tasksNonBillableActualVsPlan',
};

const getAdjustedValue = (resourceData, fieldName, allocationControlsOptions) => {
	let adjustedFieldName = fieldName;

	if (allocationControlsOptions.taskFallback) {
		const fieldFallback = TASK_FALLBACK_FIELD_MAPPING[fieldName];
		if (fieldFallback) {
			adjustedFieldName = fieldFallback;
		}
	} else {
		if (allocationControlsOptions.hideSoft) {
			adjustedFieldName = fieldName + 'Hard';
		} else if (allocationControlsOptions.hideHard) {
			adjustedFieldName = fieldName + 'Soft';
		}

		if (!allocationControlsOptions.hideSoft && allocationControlsOptions.calcWin) {
			adjustedFieldName += 'Win';
		}
	}

	return resourceData[adjustedFieldName];
};

export const getAdjustedValues = (resourceData, allocationControlsOptions) => {
	const plannedOverMinutes = getAdjustedValue(resourceData, 'plannedOverMinutes', allocationControlsOptions);

	const plannedBillableUtilization = getAdjustedValue(resourceData, 'plannedBillableUtilization', allocationControlsOptions);

	const plannedNonBillableUtilization = getAdjustedValue(
		resourceData,
		'plannedNonBillableUtilization',
		allocationControlsOptions
	);

	const plannedResourceUtilization = getAdjustedValue(resourceData, 'plannedResourceUtilization', allocationControlsOptions);

	const plannedBillableProjectMinutes = getAdjustedValue(
		resourceData,
		'plannedBillableProjectMinutes',
		allocationControlsOptions
	);

	const plannedNonBillableProjectMinutes = getAdjustedValue(
		resourceData,
		'plannedNonBillableProjectMinutes',
		allocationControlsOptions
	);

	const plannedTotalMinutes = getAdjustedValue(resourceData, 'plannedTotalMinutes', allocationControlsOptions);

	const remainingBillableUtilization = getAdjustedValue(
		resourceData,
		'remainingBillableUtilization',
		allocationControlsOptions
	);

	const remainingNonBillableUtilization = getAdjustedValue(
		resourceData,
		'remainingNonBillableUtilization',
		allocationControlsOptions
	);

	const remainingResourceUtilization = getAdjustedValue(
		resourceData,
		'remainingResourceUtilization',
		allocationControlsOptions
	);

	const remainingBillableProjectMinutes = getAdjustedValue(
		resourceData,
		'remainingBillableProjectMinutes',
		allocationControlsOptions
	);

	const remainingNonBillableProjectMinutes = getAdjustedValue(
		resourceData,
		'remainingNonBillableProjectMinutes',
		allocationControlsOptions
	);

	const remainingTotalMinutes = getAdjustedValue(resourceData, 'remainingTotalMinutes', allocationControlsOptions);
	const remainingOverMinutes = getAdjustedValue(resourceData, 'remainingOverMinutes', allocationControlsOptions);

	const billableActualVsPlan = getAdjustedValue(resourceData, 'billableActualVsPlan', allocationControlsOptions);

	const nonBillableActualVsPlan = getAdjustedValue(resourceData, 'nonBillableActualVsPlan', allocationControlsOptions);

	const totalActualVsPlan = getAdjustedValue(resourceData, 'totalActualVsPlan', allocationControlsOptions);

	const forecastBillableProjectMinutes = getAdjustedValue(
		resourceData,
		'forecastBillableProjectMinutes',
		allocationControlsOptions
	);
	const forecastNonBillableProjectMinutes = getAdjustedValue(
		resourceData,
		'forecastNonBillableProjectMinutes',
		allocationControlsOptions
	);
	const forecastTotalMinutes = getAdjustedValue(resourceData, 'forecastTotalMinutes', allocationControlsOptions);
	const forecastOverMinutes = getAdjustedValue(resourceData, 'forecastOverMinutes', allocationControlsOptions);
	const forecastBillableUtilization = getAdjustedValue(
		resourceData,
		'forecastBillableUtilization',
		allocationControlsOptions
	);
	const forecastNonBillableUtilization = getAdjustedValue(
		resourceData,
		'forecastNonBillableUtilization',
		allocationControlsOptions
	);
	const forecastResourceUtilization = getAdjustedValue(
		resourceData,
		'forecastResourceUtilization',
		allocationControlsOptions
	);

	const plannedBillableAllocationVsTask = getAdjustedValue(
		resourceData,
		'plannedBillableAllocationVsTask',
		allocationControlsOptions
	);
	const plannedNonBillableAllocationVsTask = getAdjustedValue(
		resourceData,
		'plannedNonBillableAllocationVsTask',
		allocationControlsOptions
	);
	const plannedAllocationVsTask = getAdjustedValue(resourceData, 'plannedAllocationVsTask', allocationControlsOptions);
	const remainingBillableAllocationVsTask = getAdjustedValue(
		resourceData,
		'remainingBillableAllocationVsTask',
		allocationControlsOptions
	);
	const remainingNonBillableAllocationVsTask = getAdjustedValue(
		resourceData,
		'remainingNonBillableAllocationVsTask',
		allocationControlsOptions
	);
	const remainingAllocationVsTask = getAdjustedValue(resourceData, 'remainingAllocationVsTask', allocationControlsOptions);

	const plannedBillableTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'plannedBillableTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const plannedNonBillableTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'plannedNonBillableTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const plannedTotalTimeTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'plannedTotalTimeTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const plannedBillableUtilizationTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'plannedBillableUtilizationTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const plannedNonBillableUtilizationTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'plannedNonBillableUtilizationTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const plannedResourceUtilizationTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'plannedResourceUtilizationTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const remainingBillableTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'remainingBillableTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const remainingNonBillableTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'remainingNonBillableTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const remainingTotalTimeTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'remainingTotalTimeTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const remainingBillableUtilizationTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'remainingBillableUtilizationTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const remainingNonBillableUtilizationTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'remainingNonBillableUtilizationTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const remainingResourceUtilizationTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'remainingResourceUtilizationTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const forecastBillableTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'forecastBillableTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const forecastNonBillableTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'forecastNonBillableTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const forecastTotalTimeTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'forecastTotalTimeTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const forecastBillableUtilizationTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'forecastBillableUtilizationTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const forecastNonBillableUtilizationTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'forecastNonBillableUtilizationTaskAndAllocationsCombined',
		allocationControlsOptions
	);
	const forecastResourceUtilizationTaskAndAllocationsCombined = getAdjustedValue(
		resourceData,
		'forecastResourceUtilizationTaskAndAllocationsCombined',
		allocationControlsOptions
	);

	return {
		plannedOverMinutes,
		plannedBillableUtilization,
		plannedNonBillableUtilization,
		plannedResourceUtilization,
		plannedBillableProjectMinutes,
		plannedNonBillableProjectMinutes,
		plannedTotalMinutes,
		remainingBillableUtilization,
		remainingNonBillableUtilization,
		remainingResourceUtilization,
		remainingBillableProjectMinutes,
		remainingNonBillableProjectMinutes,
		remainingTotalMinutes,
		remainingOverMinutes,
		billableActualVsPlan,
		nonBillableActualVsPlan,
		totalActualVsPlan,
		forecastBillableProjectMinutes,
		forecastNonBillableProjectMinutes,
		forecastTotalMinutes,
		forecastOverMinutes,
		forecastBillableUtilization,
		forecastNonBillableUtilization,
		forecastResourceUtilization,
		plannedBillableAllocationVsTask,
		plannedNonBillableAllocationVsTask,
		plannedAllocationVsTask,
		remainingBillableAllocationVsTask,
		remainingNonBillableAllocationVsTask,
		remainingAllocationVsTask,
		plannedBillableTaskAndAllocationsCombined,
		plannedNonBillableTaskAndAllocationsCombined,
		plannedTotalTimeTaskAndAllocationsCombined,
		plannedBillableUtilizationTaskAndAllocationsCombined,
		plannedNonBillableUtilizationTaskAndAllocationsCombined,
		plannedResourceUtilizationTaskAndAllocationsCombined,
		remainingBillableTaskAndAllocationsCombined,
		remainingNonBillableTaskAndAllocationsCombined,
		remainingTotalTimeTaskAndAllocationsCombined,
		remainingBillableUtilizationTaskAndAllocationsCombined,
		remainingNonBillableUtilizationTaskAndAllocationsCombined,
		remainingResourceUtilizationTaskAndAllocationsCombined,
		forecastBillableTaskAndAllocationsCombined,
		forecastNonBillableTaskAndAllocationsCombined,
		forecastTotalTimeTaskAndAllocationsCombined,
		forecastBillableUtilizationTaskAndAllocationsCombined,
		forecastNonBillableUtilizationTaskAndAllocationsCombined,
		forecastResourceUtilizationTaskAndAllocationsCombined,
	};
};

export const getAggregatedResourceNumbersByMonth = aggregatedResourceNumbers => {
	return aggregatedResourceNumbers.reduce((map, resourceNumbers) => {
		const date = resourceNumbers.startDate.clone().startOf('month');
		while (date.isBefore(resourceNumbers.endDate.clone().endOf('month'))) {
			map[date.month()] = resourceNumbers;
			date.add(1, 'month');
		}
		return map;
	}, {});
};

export const selectValueWithOptions = (value, valueWin, valueSoft, valueSoftWin, valueHard, allocationControlsOptions) => {
	const {calcWin, hideHard, hideSoft} = allocationControlsOptions;
	if (calcWin) {
		if (hideHard) {
			return valueSoftWin;
		} else {
			return valueWin;
		}
	} else {
		if (hideSoft) {
			return valueHard;
		} else {
			if (hideHard) {
				return valueSoft;
			} else {
				return value;
			}
		}
	}
};
