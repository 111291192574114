import React, { useMemo } from 'react';
import { EMPHASIS, StepCard, Text } from '@forecast-it/design-system';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import { FormattedMessage, useIntl } from 'react-intl';
import Styled from 'styled-components';
const PageWrapper = Styled.div `
	padding: 0 32px;
`;
const DescriptionContainer = Styled.div `
	width: 485px;
`;
const StepCardsContainer = Styled.div `
	display: flex;
	flex-direction: row;
	align-items: stretch;
	box-sizing: border-box;
	gap: 12px;
	margin: 24px 0 10px;
`;
const DeliverablesGuideSection = ({ phases, deliverables }) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const isPhasesCompleted = useMemo(() => phases.length > 0, [phases.length]);
    const isDeliverablesCompleted = useMemo(() => deliverables.length > 0, [deliverables.length]);
    const isHoursAndExpensesDisabled = useMemo(() => !isPhasesCompleted || !isDeliverablesCompleted, [isPhasesCompleted, isDeliverablesCompleted]);
    return (React.createElement(CustomScrollDiv, { horizontalcontent: true },
        React.createElement(PageWrapper, { "data-cy": 'baseline-deliverables-guide' },
            React.createElement(DescriptionContainer, null,
                React.createElement(Text, { size: '2' },
                    React.createElement(FormattedMessage, { id: 'deliverables.guide_section.description' }))),
            React.createElement(StepCardsContainer, null,
                React.createElement(StepCard, { completed: isPhasesCompleted, headerTitle: isPhasesCompleted ? formatMessage({ id: 'common.done' }) : formatMessage({ id: 'common.todo' }), headerIcon: isPhasesCompleted ? 'checkCircle' : undefined, title: formatMessage({ id: 'deliverables.guide_section.phases.title' }), icon: 'phase', button: {
                        label: formatMessage({ id: 'deliverables.guide_section.phases.button' }),
                        emphasis: isPhasesCompleted ? EMPHASIS.MEDIUM : EMPHASIS.HIGH,
                        onClick: () => undefined,
                    } },
                    React.createElement(FormattedMessage, { id: 'deliverables.guide_section.phases.description' })),
                React.createElement(StepCard, { completed: isDeliverablesCompleted, headerTitle: isDeliverablesCompleted ? formatMessage({ id: 'common.done' }) : formatMessage({ id: 'common.todo' }), headerIcon: isDeliverablesCompleted ? 'checkCircle' : undefined, title: formatMessage({ id: 'deliverables.guide_section.deliverables.title' }), icon: 'deliverable', button: {
                        label: formatMessage({ id: 'deliverables.guide_section.deliverables.button' }),
                        emphasis: isDeliverablesCompleted ? EMPHASIS.MEDIUM : EMPHASIS.HIGH,
                        onClick: () => undefined,
                    } },
                    React.createElement(FormattedMessage, { id: 'deliverables.guide_section.deliverables.description' })),
                React.createElement(StepCard, { completed: false, disabled: isHoursAndExpensesDisabled, headerTitle: isHoursAndExpensesDisabled
                        ? formatMessage({ id: 'deliverables.guide_section.hours_and_expenses.header_title' })
                        : formatMessage({ id: 'common.todo' }), headerIcon: isHoursAndExpensesDisabled ? 'lockClosed' : undefined, title: formatMessage({ id: 'deliverables.guide_section.hours_and_expenses.title' }), icon: 'timeEstimate', button: {
                        label: formatMessage({ id: 'deliverables.guide_section.hours_and_expenses.button' }),
                        emphasis: EMPHASIS.HIGH,
                        onClick: () => undefined,
                    } },
                    React.createElement(FormattedMessage, { id: 'deliverables.guide_section.hours_and_expenses.description' }))))));
};
export default DeliverablesGuideSection;
