import {keyToFilterField} from '../../../forecast-app/shared/components/filters/filter_util';
import {BUDGET_TYPE, FILTER_TYPE, GLOBAL_FILTER_FIELD, PHASE_BREAKDOWN_TYPE, ROLE_BREAKDOWN_TYPE} from '../../../constants';
import Util from '../../../forecast-app/shared/util/util';
import {getUnifiedFilterOperator, isOperator} from '../../../forecast-app/shared/components/filters/filter_logic';

export const convertProjectSearchFilters = filters => {
	const updatedFilters = [];

	if (filters !== null && filters.project) {
		const projectFilters = filters.project;
		for (let key of Object.keys(projectFilters)) {
			if (isOperator(key)) {
				continue;
			}
			const value = projectFilters[key];
			const unifiedFilterOperator = getUnifiedFilterOperator(key, projectFilters);

			if (key === FILTER_TYPE.PROJECT_TYPE) {
				const budgetTypeValueMap = {
					fixed_price: 'FIXED_PRICE',
					'non-billable': 'NON_BILLABLE',
					time_and_material: 'TIME_AND_MATERIALS',
					retainers: 'RETAINER',
				};
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.PROJECT_BUDGET_TYPE,
					operator: unifiedFilterOperator,
					value: budgetTypeValueMap[value],
				});
			} else {
				updatedFilters.push({
					field: keyToFilterField(key),
					operator: unifiedFilterOperator,
					value: value,
				});
			}
		}
	}

	return updatedFilters;
};

export const filtersToSearchQuery = (filters, addedFilters) => {
	const searchQuery = {
		filters: [],
	};
	if (addedFilters) {
		addedFilters.forEach(filter => {
			searchQuery.filters.push({
				field: keyToFilterField(filter.key),
				operator: filter.operator,
				value: filter.value,
			});
		});
	}

	if (filters?.project) {
		for (const [key, value] of Object.entries(filters.project)) {
			if (isOperator(key)) {
				continue;
			}
			const unifiedFilterOperator = getUnifiedFilterOperator(key, filters.project);
			let resolvedValue;
			if (key === 'projectType') {
				resolvedValue = value.map(filterBudgetType => {
					switch (filterBudgetType) {
						case 'time_and_material':
							return BUDGET_TYPE.TIME_AND_MATERIALS;
						case 'retainers':
							return BUDGET_TYPE.RETAINER;
						case 'fixed_price':
							return BUDGET_TYPE.FIXED_PRICE;
						case 'non-billable':
							return BUDGET_TYPE.NON_BILLABLE;
						default:
							throw new Error(`Unknown budgetType ${filterBudgetType}`);
					}
				});
			} else {
				resolvedValue = value;
			}

			searchQuery.filters.push({
				field: keyToFilterField(key),
				operator: unifiedFilterOperator,
				value: resolvedValue,
			});
		}
	}
	return searchQuery;
};

export const getPhaseName = (intl, phaseRowData) => {
	if (phaseRowData.breakdownType === PHASE_BREAKDOWN_TYPE.NO_PHASE) {
		return intl.formatMessage({id: 'project_scopes.no-scope'});
	} else if (phaseRowData.breakdownType === PHASE_BREAKDOWN_TYPE.PROJECT_TIME_REG) {
		return intl.formatMessage({id: 'project_budget.project_time_entries_with_no_tasks'});
	} else if (phaseRowData.breakdownType === PHASE_BREAKDOWN_TYPE.OFFSET) {
		return intl.formatMessage({id: 'common.offset'});
	} else if (phaseRowData.breakdownType === PHASE_BREAKDOWN_TYPE.ACCUMULATED_TOTALS) {
		return intl.formatMessage({id: 'project_budget.accumulated_totals'});
	} else {
		return phaseRowData.phase ? phaseRowData.phase.name : '';
	}
};

export const getRoleName = (intl, roleRowData) => {
	if (roleRowData.breakdownType === ROLE_BREAKDOWN_TYPE.NO_ROLE) {
		return intl.formatMessage({id: 'card_modal.no-role'});
	} else if (roleRowData.breakdownType === ROLE_BREAKDOWN_TYPE.EXPENSES) {
		return intl.formatMessage({id: 'project_budget.expenses'});
	} else {
		return roleRowData.role ? roleRowData.role.name : '';
	}
};

export const getExpenseCategoryName = (intl, expenseCategoryRowData) => {
	return expenseCategoryRowData.expenseCategory ? expenseCategoryRowData.expenseCategory.name : '';
};

export const sortBreakdownPhases = phaseFinancialBreakdown => {
	const typeSortOrder = ['PHASE', 'NO_PHASE', 'PROJECT_TIME_REG'];
	phaseFinancialBreakdown.sort((a, b) => {
		const typeIndexA = typeSortOrder.indexOf(a.breakdownType);
		const typeIndexB = typeSortOrder.indexOf(b.breakdownType);

		let comparison = typeIndexA - typeIndexB;

		if (comparison === 0 && a.breakdownType === PHASE_BREAKDOWN_TYPE.PHASE) {
			if (!a.phase.id) return 1;
			if (!b.phase.id) return -1;
			const startDateA = Util.CreateMomentDate(a.phase.startYear, a.phase.startMonth, a.phase.startDay);
			const startDateB = Util.CreateMomentDate(b.phase.startYear, b.phase.startMonth, b.phase.startDay);
			return startDateA - startDateB;
		}
		return comparison;
	});
};

export const sortBreakdownRoles = roleBreakdownData => {
	const typeSortOrder = ['ROLE', 'NO_ROLE', 'EXPENSES'];
	roleBreakdownData.sort((a, b) => {
		const typeIndexA = typeSortOrder.indexOf(a.breakdownType);
		const typeIndexB = typeSortOrder.indexOf(b.breakdownType);

		let comparison = typeIndexA - typeIndexB;

		if (comparison === 0 && a.breakdownType === ROLE_BREAKDOWN_TYPE.ROLE) {
			if (!a.role) return 1;
			if (!b.role) return -1;
			return a.role.name - b.role.name;
		}

		return comparison;
	});
};
