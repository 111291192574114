import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';

import {getFiltersAlphabetically} from '../../../forecast-app/shared/components/filters/filter_util';
import {DATE_PICKER_STYLE, ELEMENT_TYPE, FILTER_SECTION, FILTER_TYPE, HIDDEN_FEATURES, MODULE_TYPES} from '../../../constants';
import {buildHeaderBar} from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import Util from '../../../forecast-app/shared/util/util';
import {FILTER_SECTIONS} from '../../../forecast-app/shared/components/filters/FilterWrapper';
import {getSingleNumberLabel, PROJECT_PORTFOLIO_SINGLE_NUMBER} from './ProjectPortfolioSingleNumberOptions';
import tracking from '../../../tracking';
import {GROUP_BY, PROJECT_PORTFOLIO_REPORT_PAGES} from './ProjectPortfolioReport';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import CompanySetupUtil, {isFeatureHidden} from '../../../forecast-app/shared/util/CompanySetupUtil';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {PRESET_DATE_RANGE_PICKER_OPTIONS} from '../../../forecast-app/shared/components/date-picker/preset_date_range_picker';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import ProgramUtil from '../../../forecast-app/shared/util/ProgramUtil';

const ProjectPortfolioReportHeaderBar = ({
	getBottomReportHeader,
	isReportService,
	handleSetFilterValue,
	handleCsvDownload,
	selectedSingleValue,
	handleSetSingleValue,
	groupBy,
	handleSetGrouping,
	activePage,
	selectedDates,
	selectedYear,
	onChangeDates,
	onChangeYear,
	handlePresetDateRangeChange,
	initialPresetDateRange,
	showDatePicker,
	selectedPeriodEyeOptions,
	selectedMonthByMonthEyeOptions,
	viewer: {id: viewerId, filters},
}) => {
	const intl = useIntl();
	const hasFinance = CompanySetupUtil.hasFinance();
	const hasRevenueWithoutCostAccess = Util.hasRevenueWithoutCostAccess();
	const getHeaderLeftContent = () => {};

	const getHeaderRightContent = () => {
		const projectFilters = [
			FILTER_TYPE.PROJECT,
			FILTER_TYPE.PROJECT_STATUS,
			FILTER_TYPE.CONTACT,
			FILTER_TYPE.PROJECT_STAGE,
			FILTER_TYPE.CLIENT,
			FILTER_TYPE.LABEL,
			FILTER_TYPE.DEADLINE,
			FILTER_TYPE.PRIORITY_LEVEL,
			...(hasModule(MODULE_TYPES.PROGRAMS) ? [FILTER_TYPE.PROGRAM] : []),
		];
		if (hasFinance) {
			projectFilters.push(FILTER_TYPE.PROJECT_TYPE, FILTER_TYPE.RATE_CARD);
		}
		if (hasFeatureFlag('invoicing_pathways')) {
			projectFilters.push(FILTER_TYPE.BILL_FROM);
			projectFilters.push(FILTER_TYPE.BILL_TO);
		}
		const peopleFilters = [];
		const taskFilters = [];
		const filterProps = {
			defaultSection: FILTER_SECTIONS.PROJECTS,
			projectFilters: getFiltersAlphabetically(projectFilters, intl.formatMessage),
			peopleFilters,
			taskFilters,
			appliedFiltersName: `filters-project-portfolio-report`,
			filterSection: FILTER_SECTION.FINANCIAL_PORTFOLIO_REPORT,
			primaryFilters: {
				[FILTER_SECTIONS.PROJECTS]: [
					FILTER_TYPE.PROJECT,
					FILTER_TYPE.LABEL,
					FILTER_TYPE.PROJECT_STAGE,
					FILTER_TYPE.CLIENT,
					FILTER_TYPE.CONTACT,
					FILTER_TYPE.PROJECT_TYPE,
				],
			},
			viewer: {id: viewerId, filters},
			operatorOptions: {
				allowExclude: hasFeatureFlag('unified_filters_reports'),
				allowRequireAll: hasFeatureFlag('unified_filters_reports'),
			},
		};

		const additionalProps = {filterProps};

		if (activePage === PROJECT_PORTFOLIO_REPORT_PAGES.PERIOD.path) {
			additionalProps.eyeProps = {
				eyeOptions: selectedPeriodEyeOptions,
			};
		} else if (activePage === PROJECT_PORTFOLIO_REPORT_PAGES.MONTH_BY_MONTH.path) {
			additionalProps.eyeProps = {
				eyeOptions: selectedMonthByMonthEyeOptions,
			};
		}

		if (hasFeatureFlag('reports_redesign') && isReportService) {
			additionalProps.onFiltersChange = handleSetFilterValue;
		}

		const content = [];

		const filteredHeaderElements = getBottomReportHeader(additionalProps);

		content.push(...filteredHeaderElements);

		const groupingOptions = [
			{value: GROUP_BY.NO_GROUPING, label: intl.formatMessage({id: 'common.no_grouping'})},
			{
				value: GROUP_BY.CONNECTED_PROJECT,
				label: intl.formatMessage({id: 'project_portfolio_report.group_by_connected_project'}),
			},
			...(hasModule(MODULE_TYPES.PROGRAMS)
				? [
						{
							value: GROUP_BY.PROGRAM,
							label: intl.formatMessage(
								{id: 'project_portfolio_report.group_by_program'},
								{program: ProgramUtil.programText(intl.formatMessage)}
							),
						},
				  ]
				: []),
			{
				value: GROUP_BY.CLIENT,
				label: intl.formatMessage({id: 'common.group_by_client'}),
			},
		];

		content.push({
			type: ELEMENT_TYPE.DROPDOWN,
			dropdownOptions: groupingOptions,
			value: groupBy,
			callback: selectedGroupingOption => {
				tracking.trackElementClicked('Grouping Changed', {
					grouping_option: selectedGroupingOption.label,
				});
				trackEvent('Grouping', 'Changed', {value: selectedGroupingOption?.value});
				handleSetGrouping(selectedGroupingOption.value);
			},
			buttonCy: 'grouping-dropdown',
			listDataCy: 'grouping-dropdown-item',
		});

		content.push({
			type: ELEMENT_TYPE.CSV,
			callback: handleCsvDownload,
		});

		if (hasFinance) {
			let singleValueOptions = [
				...(!isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? [
							{
								value: PROJECT_PORTFOLIO_SINGLE_NUMBER.BILLABLE_ACTUAL_TIME_AND_EXPENSES,
								label: getSingleNumberLabel(
									PROJECT_PORTFOLIO_SINGLE_NUMBER.BILLABLE_ACTUAL_TIME_AND_EXPENSES,
									intl
								),
							},
					  ]
					: []),
				{
					value: PROJECT_PORTFOLIO_SINGLE_NUMBER.ACTUAL_COST,
					label: getSingleNumberLabel(PROJECT_PORTFOLIO_SINGLE_NUMBER.ACTUAL_COST, intl),
				},
				...(!isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? [
							{
								value: PROJECT_PORTFOLIO_SINGLE_NUMBER.ACTUAL_PROFIT,
								label: getSingleNumberLabel(PROJECT_PORTFOLIO_SINGLE_NUMBER.ACTUAL_PROFIT, intl),
							},
							{
								value: PROJECT_PORTFOLIO_SINGLE_NUMBER.PLAN_REVENUE,
								label: getSingleNumberLabel(PROJECT_PORTFOLIO_SINGLE_NUMBER.PLAN_REVENUE, intl),
							},
					  ]
					: []),
				{
					value: PROJECT_PORTFOLIO_SINGLE_NUMBER.PLAN_COST,
					label: getSingleNumberLabel(PROJECT_PORTFOLIO_SINGLE_NUMBER.PLAN_COST, intl),
				},
				...(!isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? [
							{
								value: PROJECT_PORTFOLIO_SINGLE_NUMBER.PLAN_PROFIT,
								label: getSingleNumberLabel(PROJECT_PORTFOLIO_SINGLE_NUMBER.PLAN_PROFIT, intl),
							},
							{
								value: PROJECT_PORTFOLIO_SINGLE_NUMBER.BILLABLE_FORECAST_TIME_AND_EXPENSES_TO_COMPLETE,
								label: getSingleNumberLabel(
									PROJECT_PORTFOLIO_SINGLE_NUMBER.BILLABLE_FORECAST_TIME_AND_EXPENSES_TO_COMPLETE,
									intl
								),
							},
					  ]
					: []),
				{
					value: PROJECT_PORTFOLIO_SINGLE_NUMBER.FORECAST_COST_TO_COMPLETE,
					label: getSingleNumberLabel(PROJECT_PORTFOLIO_SINGLE_NUMBER.FORECAST_COST_TO_COMPLETE, intl),
				},
				...(!isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? [
							{
								value: PROJECT_PORTFOLIO_SINGLE_NUMBER.FORECAST_PROFIT_TO_COMPLETE,
								label: getSingleNumberLabel(PROJECT_PORTFOLIO_SINGLE_NUMBER.FORECAST_PROFIT_TO_COMPLETE, intl),
							},
							{
								value: PROJECT_PORTFOLIO_SINGLE_NUMBER.BILLABLE_TOTAL_TIME_AND_EXPENSES_AT_COMPLETION,
								label: getSingleNumberLabel(
									PROJECT_PORTFOLIO_SINGLE_NUMBER.BILLABLE_TOTAL_TIME_AND_EXPENSES_AT_COMPLETION,
									intl
								),
							},
					  ]
					: []),
				{
					value: PROJECT_PORTFOLIO_SINGLE_NUMBER.TOTAL_COST_AT_COMPLETION,
					label: getSingleNumberLabel(PROJECT_PORTFOLIO_SINGLE_NUMBER.TOTAL_COST_AT_COMPLETION, intl),
				},
				...(!isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? [
							{
								value: PROJECT_PORTFOLIO_SINGLE_NUMBER.TOTAL_PROFIT_AT_COMPLETION,
								label: getSingleNumberLabel(PROJECT_PORTFOLIO_SINGLE_NUMBER.TOTAL_PROFIT_AT_COMPLETION, intl),
							},
							{
								value: PROJECT_PORTFOLIO_SINGLE_NUMBER.INVOICED,
								label: getSingleNumberLabel(PROJECT_PORTFOLIO_SINGLE_NUMBER.INVOICED, intl),
							},
							{
								value: PROJECT_PORTFOLIO_SINGLE_NUMBER.INVOICE_PAID,
								label: getSingleNumberLabel(PROJECT_PORTFOLIO_SINGLE_NUMBER.INVOICE_PAID, intl),
							},
					  ]
					: []),
				...(!isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? [
							{
								value: PROJECT_PORTFOLIO_SINGLE_NUMBER.RECOGNITION_AMOUNT,
								label: getSingleNumberLabel(PROJECT_PORTFOLIO_SINGLE_NUMBER.RECOGNITION_AMOUNT, intl),
							},
					  ]
					: []),
			];

			if (hasRevenueWithoutCostAccess) {
				singleValueOptions = singleValueOptions.filter(
					item =>
						![
							PROJECT_PORTFOLIO_SINGLE_NUMBER.ACTUAL_COST,
							PROJECT_PORTFOLIO_SINGLE_NUMBER.ACTUAL_PROFIT,
							PROJECT_PORTFOLIO_SINGLE_NUMBER.PLAN_COST,
							PROJECT_PORTFOLIO_SINGLE_NUMBER.PLAN_PROFIT,
							PROJECT_PORTFOLIO_SINGLE_NUMBER.FORECAST_COST_TO_COMPLETE,
							PROJECT_PORTFOLIO_SINGLE_NUMBER.FORECAST_PROFIT_TO_COMPLETE,
							PROJECT_PORTFOLIO_SINGLE_NUMBER.TOTAL_COST_AT_COMPLETION,
							PROJECT_PORTFOLIO_SINGLE_NUMBER.TOTAL_PROFIT_AT_COMPLETION,
						].includes(item.value)
				);
			}

			// Change selected value (if it is not cost related already) for reports that were created and saved before revenue was turned off
			if (!singleValueOptions.some(item => item.value === selectedSingleValue)) {
				selectedSingleValue = PROJECT_PORTFOLIO_SINGLE_NUMBER.ACTUAL_COST;
			}

			content.push({
				type: ELEMENT_TYPE.LEFT_DROPDOWN,
				dropdownOptions: singleValueOptions,
				customWidth: 350,
				value: selectedSingleValue,
				callback: newSelectedSingleValueType => {
					tracking.trackElementClicked('Single value type changed', {
						single_value_type: newSelectedSingleValueType.label,
					});
					trackEvent('Single Value', 'Changed');
					handleSetSingleValue(newSelectedSingleValueType.value);
				},
				placeholder: '',
				userpilot: 'project-portfolio-single-value-dropdown',
			});
		}

		if (activePage === PROJECT_PORTFOLIO_REPORT_PAGES.PERIOD.path) {
			content.push({
				type: ELEMENT_TYPE.PRESET_DATE_RANGE_PICKER,
				handleDateRangeChange: handlePresetDateRangeChange,
				initialPresetDateRange: initialPresetDateRange,
				options: [
					PRESET_DATE_RANGE_PICKER_OPTIONS.ALL_TIME,
					PRESET_DATE_RANGE_PICKER_OPTIONS.PREVIOUS_MONTH,
					PRESET_DATE_RANGE_PICKER_OPTIONS.CURRENT_MONTH,
					PRESET_DATE_RANGE_PICKER_OPTIONS.NEXT_MONTH,
					PRESET_DATE_RANGE_PICKER_OPTIONS.PREVIOUS_YEAR,
					PRESET_DATE_RANGE_PICKER_OPTIONS.CURRENT_YEAR,
					PRESET_DATE_RANGE_PICKER_OPTIONS.YEAR_TO_DATE,
					PRESET_DATE_RANGE_PICKER_OPTIONS.CUSTOM_RANGE,
				],
				userpilot: 'project-portfolio-single-value-dropdown',
			});
		}

		if (activePage === PROJECT_PORTFOLIO_REPORT_PAGES.PERIOD.path && showDatePicker) {
			content.push({
				type: ELEMENT_TYPE.DATE_PICKER,
				isNewDateRange: true,
				startDate: selectedDates.startDate,
				endDate: selectedDates.endDate,
				handleDateRangeChange: onChangeDates,
				colorInherited: true,
				compactShowYear: true,
				datePickerStyle: DATE_PICKER_STYLE.SLIM,
				calendarOffsetX: 0,
				clearable: false,
				userpilot: 'project-portfolio-report-date-picker',
				cy: 'ppr-date-picker',
			});
		} else if (activePage !== PROJECT_PORTFOLIO_REPORT_PAGES.PERIOD.path) {
			content.push({
				type: ELEMENT_TYPE.YEAR_STEPPER,
				startYear: selectedYear,
				onYearChanged: onChangeYear,
			});
		}

		return content;
	};

	return buildHeaderBar(getHeaderLeftContent(), getHeaderRightContent(), {noPadding: true, tlnPadding: false});
};

export default createFragmentContainer(ProjectPortfolioReportHeaderBar, {
	viewer: graphql`
		fragment ProjectPortfolioReportHeaderBar_viewer on Viewer {
			id
			availableFeatureFlags {
				key
			}
			company {
				tier
				modules {
					moduleType
				}
				priorityLevels {
					edges {
						node {
							id
						}
					}
				}
			}
			filters(first: 1000000, filterSection: FINANCIAL_PORTFOLIO_REPORT) @connection(key: "Viewer_filters", filters: []) {
				edges {
					node {
						id
						name
						section
						value
						updatedAt
					}
				}
			}
		}
	`,
});
